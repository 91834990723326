import React, { useState, useEffect } from 'react';
// import axios from 'axios'; // for Eric's axios call
import * as yup from 'yup';

import { Form } from 'react-bootstrap';

import { axiosWithAuth } from '../utils/axiosWithAuth';
import { useHistory } from 'react-router-dom';
import axios from 'axios'

const url = 'https://vr-funding-1.herokuapp.com/api/login';

// creating a Login Form
const initialformValues = {
  email: '',
  password: '',
};

const initialformErrors = {
  email: '',
  password: '',
};

// Yup Library for Login information
const formSchema = yup.object().shape({
  //email information
  email: yup
    .string()
    .email('a valid email is required')
    .required('email is required'),

  //password field information
  password: yup
    .string()
    .min(8, 'characters a must!')
    .required(
      'contain Capital, lowercase, number and special character !@#$%^&*'
    ),
});

function LoginForm(props) {
  const [users, setUsers] = useState([]);
  const [formValues, setformValues] = useState(initialformValues);
  let { push } = useHistory();

  //For Registration button
  const [formDisabled, setformDisabled] = useState(true);

  //Tracking Validation Errors
  const [formErrors, setformErrors] = useState(initialformErrors);

  // useEffect(() => {
  //   axios.post('https://vr-funding-1.herokuapp.com/api/login')
  //   .then(res => {
  //     console.log(res, "axios res")
  //   })
  // })


  //******************************* Axios get and Post Request**********************
  // const getUsers = () => {
    //calling for API
  //   axios
  //     .get(url)
  //     .then((res) => {
  //       setUsers(res.data.data);
  //       console.log(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   getUsers();
  // }, []);

  // const postUser = (user) => {if crafting post request information here}
  const postUser = (user) => {
    //crafting post request.
    //Eric's axios call prior axiosWithAuth
    // axios
    //   .post(url, user)
    //   .then((res) => {
    //     setUsers([...users, res.data]);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // ***************************************End of Axios Get and Post Request*****************
    
    //loging with axiosWithAuth

    axiosWithAuth()
      .post('/api/login', user)
      .then((res) => {
        console.log(res, 'res from post');
        localStorage.setItem('token', JSON.stringify(res.data.token));
        localStorage.setItem('userId', JSON.stringify(res.data.data.id));
        push('/dashboard');
        // setUsers([...users, res.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    formSchema.isValid(formValues).then((valid) => {
      setformDisabled(!valid);
    });
  }, [formValues]);

  //For onSubmit***********************************************
  const onSubmit = (evt) => {
    evt.preventDefault();

    const newUser = {
      email: formValues.email,
      password: formValues.password,
      loggedIn: true,
    };
    postUser(newUser);
    setformValues(initialformValues);
  };

  //For onInputCHange*******************************************
  const onInputChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    yup
      .reach(formSchema, name)
      .validate(value)
      .then((valid) => {
        setformErrors({
          ...formErrors,
          [name]: '',
        });
      })
      .catch((err) => {
        setformErrors({
          ...formErrors,
          [name]: err.errors[0],
        });
      });

    setformValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    
    <form onSubmit={onSubmit}>
      <h1>Sign In</h1>
      {/* Email info  */}
      <Form.Group controlId='formGroupEmail'>
        <label>Email:</label>
        <br />
        <input
          name='email'
          value={formValues.email}
          onChange={onInputChange}
          text='email'
          placeholder='Email'
        />
        <br />

        {/* Password info */}
        <label>Password:</label>
        <br />
        <input
          name='password'
          type='password'
          placeholder='Password'
          value={formValues.password}
          onChange={onInputChange}
        />
      </Form.Group>
      {/* Button dealing with the user submit form in form_test.js*/}
      <button>Log In</button>
      <br />
      <br />
      <a href='#'>Forgot Password</a> | <a href='/'>New User</a>
    </form>
  );
}

export default LoginForm;
