import React from 'react'
import { Card, ListGroup } from 'react-bootstrap';


function Logincard() {
    return (
        <Card style={{ width: '18rem' }}>
        <ListGroup variant="flush">
          <ListGroup.Item>Email</ListGroup.Item>
          <ListGroup.Item>Password</ListGroup.Item>
          
        </ListGroup>
      </Card>
    )
}

export default Logincard;
