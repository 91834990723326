import axios from 'axios';

export let axiosWithAuth = () => {
    let token = JSON.parse(localStorage.getItem('token'))
    return axios.create({
        headers: {
            Authorization : token
        },
        baseURL: 'https://build-week-virtual-reality.herokuapp.com'
    })
}   


// 


// baseURL: 'https://build-week-virtual-reality.herokuapp.com/' Hunter

// baseURL: 'https://vr-funding-1.herokuapp.com' Nathan