import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import RegForm from './components/RegForm';
import Login from './components/Login';
import Navi from './components/Nav';
import Logincard from './cards/Logincard';


import PrivateRoute from './components/PrivateRoute';
import Dashboard from './components/Dashboard';


export default function App() {

  /////////////////////////////Router Information/////////////////////////
  return (
    // Wrapping in Router & adding links
    // placing "exact" on Route to make it go to the specific path
    <Router>
    
      <div className='App'>
        {/*This is where the search bar is located.   */}
        <Navi/>
        <Switch>
          <PrivateRoute exact path='/dashboard' component={Dashboard}/>
          <Route exact path='/'>
          {/* <Logincard /> */}
          <RegForm/>
          </Route>
          <Route path='../Login'/>
          <Login/>
        </Switch>
      </div>

    </Router>
  );
}