import React from 'react';
import styled from 'styled-components'
const Cardholder = styled.div`
display: flex;
`
const Card = styled.div `
padding:5%;
width: 30%;
margin: 0 auto;
margin-top: 10%;
padding: 1%;
display:flex;
`
const ProjectLayout = props => {
    // console.log(props.project.id,'sdfsdfdfffff')
    return(
        <Cardholder>
            <Card>
                <h1>{props.project.name}</h1>
                {/* <h3>{props.project.description}</h3>
                <h3>{props.project.fundGoal}</h3>
                <h3>{props.project.currentFund}</h3> */}
            </Card>
            <button onClick={() => props.deleteProject(props.project.id)}>Delete</button>
        </Cardholder>
    )
}
export default ProjectLayout;