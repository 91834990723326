import React, { useState, useEffect } from 'react';
import {axiosWithAuth} from '../utils/axiosWithAuth';
import AddProject from './AddProject';
import ProjectList from './ProjectList';
let Dashboard = () => {
    const [projects, setProjects] = useState([])
    useEffect(() => {
        axiosWithAuth()
        .get('https://vr-funding-1.herokuapp.com/api/projects')
        .then((response) => {
            console.log(response.data.data, '.responseDASH');
            setProjects(response.data.data)
        });
    }, [])
    // console.log(projects)
    return (
        <div>
            <h1>Inside Dashboard</h1>
            {/* <AddProject /> */}
            <ProjectList projects = {projects} />
        </div>
    )
}
export default Dashboard;



// import React from 'react';
// import { useHistory } from 'react-router-dom';
// import AddProject from './AddProject';

// let Dashboard = () => {
//     return (
//         <section className='dash-container'>
//         <div className='profile-container'>
//             <h2>Dashboard</h2>
//             <div className='profile-card'>
//                 First Name
//                 <br/>
//                 Last Name
//                 <br/>
//                 Projects : # 
//             </div>
//         </div>
//         <div className='projects-container'>
//             <nav>
//                 <h4>Edit</h4>
//                 <h4>Create</h4>
//                 <h4>Funded</h4>
//             </nav>
//             {/* map through project */}
//             <ul className='user-project-container'>
//                 <li>
//                     <h5>Projects</h5>
//                     <span>Edit</span>
//                     <span>X</span>
//                 </li>
//             </ul>
//         </div>
//         <AddProject/>
//         </section>
//     )
// }

// export default Dashboard;