import React, { useState, useEffect } from 'react';
import {axiosWithAuth} from '../utils/axiosWithAuth.js';
import ProjectLayout from './ProjectLayout.js'
import axios from 'axios';
import AddProject from './AddProject';


const ProjectList = (props) => {
    let [userProjects, setUsersProjects] = useState([]);
    let [trigger, setTrigger] = useState(0);
    // console.log(props.projects)
    console.log(userProjects, 'userProjects')
    let currentUserId = JSON.parse(localStorage.getItem('userId'))


    useEffect(() => {
        axios
        .get('https://build-week-virtual-reality.herokuapp.com/api/projects')
        .then(res => {
            // console.log(res, 'userProjects')
            setUsersProjects(res.data.data)
        })
    }, [trigger])

    let filteredList = userProjects.filter(projects => (
        projects.user_id === currentUserId
    ))
    // console.log(filteredList, 'filteredlist')

    let deleteProject = projectId => {
        axiosWithAuth()
        .delete(`/api/projects/${projectId}`)
        .then(res => {
            console.log(res)
        })
        .catch(err => {
            console.log(err)
        })
    }

    let update

    return(
        <>
        <div>
            {filteredList.map((projects) => {
                return <ProjectLayout deleteProject={deleteProject} project = {projects} key = {projects.id} />;
            })}
        </div>
        <AddProject trigger={setTrigger} />
        </>
        
    )
}
export default ProjectList;