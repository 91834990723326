import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as yup from 'yup';

import { Form } from 'react-bootstrap';

import { useHistory } from 'react-router-dom';

const url = 'https://build-week-virtual-reality.herokuapp.com/api/register';

// creating a registration form
const initialformValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  //check box will let us know that customer approved of terms
  termsAgreement: false,
};

const initialformErrors = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  termsAgreement: '',
};

// Using Yup Library here.

const formSchema = yup.object().shape({
  //firstname information
  firstName: yup
    .string()
    .min(4, 'name must have at least 4 characters')
    .required('name is required'),

  //lastname information
  lastName: yup
    .string()
    .min(4, 'name must have at least 4 characters')
    .required('name is required'),

  //email information
  email: yup
    .string()
    .email('a valid email is required')
    .required('email is required'),

  //password field information
  password: yup
    .string()
    .min(8, 'characters a must!')
    .required(
      'contain Capital, lowercase, number and special character !@#$%^&*'
    ),
});

function RegForm(props) {
  const [users, setUsers] = useState([]);
  const [formValues, setformValues] = useState(initialformValues);
  let { push } = useHistory();
  
  //For Registration button
  const [formDisabled, setformDisabled] = useState(true);

  //Tracking Validation Errors
  const [formErrors, setformErrors] = useState(initialformErrors);

  // const postUser = (user) => {if crafting post request information here}
  const postUser = (user) => {
    //crafting post request.
    axios
      .post(url, user)
      .then((res) => {
        setUsers([...users, res.data]);
        push('/login');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    formSchema.isValid(formValues).then((valid) => {
      setformDisabled(!valid);
    });
  }, [formValues]);

  //For onSubmit***********************************************
  const onSubmit = (evt) => {
    evt.preventDefault();

    const newUser = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      password: formValues.password,
      loggedIn: true,
    };
    postUser(newUser);
    setformValues(initialformValues);
  };

  //For onInputCHange*******************************************
  const onInputChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    yup
      .reach(formSchema, name)
      .validate(value)
      .then((valid) => {
        setformErrors({
          ...formErrors,
          [name]: '',
        });
      })
      .catch((err) => {
        setformErrors({
          ...formErrors,
          [name]: err.errors[0],
        });
      });

    setformValues({
      ...formValues,
      [name]: value,
    });
  };

  // Terms agreement Checkbox*******************************************
  const onCheckboxChange = (evt) => {
    const { name } = evt.target;
    const isChecked = evt.target.checked;

    setformValues({
      ...formValues,
      [name]: isChecked,
    });
  };

  // ************************************************************************

  return (
    <form onSubmit={onSubmit}>
      {/* Name info*/}
      <h2>Register Here</h2>
      <br />
      <Form.Group controlId='formGroupEmail'>
        <label>First Name:</label>
        <br />
        <input
          name='firstName'
          text='text'
          placeholder='First Name'
          value={formValues.firstName}
          onChange={onInputChange}
        />
        <br />
        <br />
        <label>Last Name:</label>

        <br />

        <input
          name='lastName'
          text='text'
          placeholder='Last Name'
          value={formValues.lastName}
          onChange={onInputChange}
        />

        <br />
        <br />

        {/* Email info  */}
        <label>Email:</label>
        <br />

        <input
          name='email'
          text='text'
          placeholder='Email'
          value={formValues.email}
          onChange={onInputChange}
        />
        <br />
        <br />

        {/* Password info */}
        <label>Password:</label>
        <br />
        <input
          name='password'
          type='password'
          placeholder='Password'
          value={formValues.password}
          onChange={onInputChange}
        />
        <br />

        {/* Terms of Service */}
        <h6>
          <p></p>I hereby agree that the information I am providing is and
          accurate.
          <p></p>
          Check the box if you agree to these terms.
        </h6>
        <label>Terms Agreement:</label>
        <input
          name='termsAgreement'
          type='checkbox'
          checked={formValues.termsAgreement}
          onChange={onCheckboxChange}
        />
      </Form.Group>
      {/* Button dealing with the user submit form in form_test.js*/}
      <button>Submit!</button>
    </form>
  );
}

export default RegForm;
