import React from 'react';
import { Navbar, Nav, FormControl, Button, Form } from 'react-bootstrap';

function Navi() {

  return (
    <Navbar bg='dark' variant='dark'>
      {/* <Navbar.Brand href='#home'>Navbar</Navbar.Brand> */}
      <Nav className='mr-auto'>
        <Nav.Link href='/'>Registration</Nav.Link>
        <Nav.Link href='/Login'>Login</Nav.Link>
        <Nav.Link href='#Projects'>Projects</Nav.Link>
      </Nav>
      <Form inline>
        <FormControl type='text' placeholder='Search' className='mr-sm-2' />
        <Button variant='outline-info'>Search</Button>
      </Form>
    </Navbar>
  );
}

export default Navi;
