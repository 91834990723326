import React, { useState, useEffect } from 'react';
import {axiosWithAuth} from '../utils/axiosWithAuth';
import { useHistory } from 'react-router-dom';

const AddProject = (props) => {
    console.log(props)
    let {push} = useHistory();
    // useEffect(() => {
    //     axiosWithAuth()
    //     .get('/api/users')
    //     .then(res => {
    //         console.log(res, '.thenRes')
    //     })
    // }, [])


    let currentUser = JSON.parse(localStorage.getItem('userId'))
    // console.log(currentUser, 'current user')
    const [newProject, setNewProject] = useState({
        name: '',
        description: '',
        fundGoal: '',
        currentFund: '',
        user_id: currentUser
    });
    // console.log(newProject)
    const handleSubmit = (evt) => {
        console.log(newProject, 'insideSub')
        evt.preventDefault()
        axiosWithAuth()
        .post('/api/projects', newProject)
        .then((response) => {
            // props.setProjectList(response.data)
            // props.setTrigger(Math.random());
            refresh()
            console.log('Here is a new Project', response)
        })
        .catch(err => {
            console.log(err, 'error')
        })
    }
    const refresh = () => {
        window.location.reload(true)
    }
    return (
        <div>
            <form onSubmit = {handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                <h3>Get your Project funded!</h3>
                <label>Project</label>
                <input type = 'text'
                name ='name'
                placeholder = 'Project Name'
                onChange = {evt => setNewProject({...newProject, name: evt.target.value})}
                value = {newProject.name}
                />
                <label>Description</label>
                <input type = 'text'
                name =  'description'
                placeholder = 'Tell us about your Project'
                onChange = {evt => setNewProject({...newProject, description: evt.target.value})}
                value = {newProject.description}
                />
                <label>Funding Goal</label>
                <input type = 'number'
                name = 'fundGoal'
                placeholder = 'How much do you need?'
                onChange = {evt => setNewProject({...newProject, fundGoal: evt.target.value})}
                value = {newProject.fundGoal} 
                />
                <label>Current Funding</label>
                <input type = 'number'
                name = 'currentFund'
                placeholder = 'How much do you have?'
                onChange = {evt => setNewProject({...newProject, currentFund: evt.target.value})}
                value = {newProject.currentFund}
                />
                <button> Publish your Project </button>
            </form>
        </div>
    )
}
export default AddProject